export const resources = {
  en: {
    translation: {
      "title.share.your": "SHARE YOUR",
      "title.rookie.mistake": "ROOKIE MISTAKE",
      "button.click.start": "Click to Start",
      win: "WIN",
      big: "BIG",
      prices: "PRIZES",
      skip: "SKIP",
      retake: "Retake",
      next: "Next",
      "title.form.fill.your.details": "Please fill your details",
      "label.input.caption": "Write a caption for your photo",
      "label.input.name": "Name",
      "label.input.mobile": "Mobile",
      "label.input.email": "Email",
      "label.input.region": "City",
      "btn.submit": "Submit",
      "title.thank.you1": "Thank you!",
      "title.thank.you2": "your submission",
      "desc.thank.you": "Your have entered the draw to win Love Bracelet.",
      "btn.continue": "Continue",
      "choose.your.region": "Choose your region",
    },
  },
  ar: {
    translation: {
      "title.share.your": "شارك",
      "title.rookie.mistake": "خطأ الصاعد",
      "button.click.start": "أضغط هنا للمشاركة",
      win: "يفوز",
      big: "كبير",
      prices: "الأسعار",
      skip: "يتخطى",
      retake: "إعادة",
      next: "التالي",
      "title.form.fill.your.details": "الرجاء تعبئة بياناتك",
      "label.input.caption": "أكتب تعليقك على صورتك",
      "label.input.name": "الاسم",
      "label.input.mobile": "الجوال",
      "label.input.email": "البريد الالكتروني",
      "label.input.region": "مدينة",
      "btn.submit": "تسجيل",
      "title.thank.you1": "شكراً لتسجيلك معنا",
      "title.thank.you2": "تقديمك",
      "desc.thank.you": "لقد دخلت السحب للفوز بسوار الحب.",
      "btn.continue": "يكمل",
      "choose.your.region": "اختر منطقتك",
    },
  },
};
